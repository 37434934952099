





















































import { getCardPwd } from '@/api/order';
import { GetPromiseReturn } from '@/api/type/tool';
import { Vue, Component } from 'vue-property-decorator';
import VueQr from 'vue-qr';
type ICardData = GetPromiseReturn<typeof getCardPwd>['payload'];
@Component({
  name: '',
  components: { VueQr }
})
export default class extends Vue {
  cardData = {} as ICardData;
  qrcodeFlag = 'QRCode:';

  get virtualName() {
    let str = this.cardData.spuName + this.cardData.skuName || '';
    if (str.length > 35) {
      str = str.substring(0, 35);
      str += '...';
    }
    return str;
  }

  get orderCode() {
    return this.$route.params.orderCode;
  }

  getIsQrcode(code?: string) {
    if (typeof code === 'string') {
      return code.includes('QRCode');
    }
    return false;
  }

  async mounted() {
    const { payload } = await getCardPwd(this.orderCode);
    this.cardData = payload;
  }
}
